import { template as template_7c1deb179a6b4957a7f9492029edadf8 } from "@ember/template-compiler";
const FKText = template_7c1deb179a6b4957a7f9492029edadf8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
