import { template as template_addce53725ad4dbebef002c8824e3f34 } from "@ember/template-compiler";
const WelcomeHeader = template_addce53725ad4dbebef002c8824e3f34(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
